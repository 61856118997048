<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-15 10:43:37
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-28 22:52:32
-->
<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="600"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-model-item label="经销商" prop="dealerId">
        <a-input v-model="rowData.dealerName" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item label="固定配额" prop="">
        <a-input v-model="orderCount" disabled placeholder="固定配额"></a-input>
      </a-form-model-item>
      <a-form-model-item label="调整配额至" prop="temporaryQuota">
        <a-input-number :min="rowData.number" v-model="rowData.orderCount" placeholder="调整配额"></a-input-number>
      </a-form-model-item>
      <!-- <a-form-model-item label="剩余数量" prop="surplusPreOrderCount">
        <a-input v-model="rowData.surplusPreOrderCount" placeholder="剩余数量"></a-input>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>
import { addDealerPreOrdersNum, editDealerPreOrdersNum , selectByIdDealerPreOrdersNum} from '../api/DealerPreOrdersNumApi'
import SelectDealerModal from '../../common/SelectDealerModal.vue'

export default {
  components: {
    SelectDealerModal,
  },

  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      orderCount: '',
      // 表单验证
      formRule: {
        dealerId: [{ required: true, message: '请输入经销商', trigger: 'blur' }],
        orderCount: [{ required: true, message: '请输入总数', trigger: 'blur' }],
        surplusPreOrderCount: [{ required: true, message: '请输入剩余数量', trigger: 'blur' }],
        temporaryQuota: [{ required: true, message: '请输入临时数量', trigger: 'blur' }],
      },
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.rowData.dealerId = row.dealerId
      selectByIdDealerPreOrdersNum(row.dealerId).then(res => {
        this.rowData = res.body
        this.rowData.number = res.body.orderCount - res.body.surplusPreOrderCount
        this.orderCount = res.body.orderCount
      })
    },

    toSelectConsumer(row) {
      this.$set(this.rowData, 'dealerId', row.id)
      this.$set(this.rowData, 'dealerName', row.name)
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res =
          this.handle === 'add' ? await addDealerPreOrdersNum(this.rowData) : await editDealerPreOrdersNum(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
